import React from "react"

import SvgIcon from "../components/SvgIcon"

const IconYoutube = () => {
  return (
    <SvgIcon viewBox="0 -1 20 20">
      <path d="M10.433 16.807l-4.097-.075c-1.326-.026-2.656.026-3.956-.244C.4 16.084.26 14.102.115 12.44a28.367 28.367 0 01.257-7.033c.215-1.3 1.063-2.076 2.373-2.16 4.422-.306 8.874-.27 13.285-.127.466.013.936.085 1.395.166 2.269.398 2.324 2.643 2.47 4.533.148 1.91.086 3.83-.195 5.726-.224 1.57-.655 2.888-2.47 3.015-2.275.166-4.498.3-6.779.257 0-.01-.013-.01-.02-.01zm-2.409-3.976l5.1-2.93c-1.717-.984-3.395-1.952-5.1-2.93v5.86z" />
    </SvgIcon>
  )
}

export default IconYoutube
